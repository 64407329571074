<template>
  <popup :visible="visible">
    <div class="alert">
      <div class="content">
        <h1>{{ title }}</h1>
        <slot />
      </div>
      <div class="buttons">
        <button @click="$emit('close')">OK</button>
      </div>
    </div>
  </popup>
</template>

<script>
import Popup from './Popup.vue'

export default {
  components: {
    Popup
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    }
  }
};
</script>

<style scoped lang="scss">
.alert {
  text-align: left;
  .buttons {
    width: 100%;
    text-align: center;
  }
}
</style>
