<template>
  <div class="container">
    <div class="header">
      <div class="title">scan4enter</div>
      <div class="powered-container">
        <div class="powered">
          powered by
          <img class="logo" src="/ma_logo.svg" alt="Mechatronik Austria" />
        </div>
      </div>
    </div>
    <div class="content">
      <div class="view">
        <router-view />
      </div>
    </div>

    <div class="impressum" @click="impressum=true">
      Impressum
    </div>
    <popup :visible="impressum">
      <h1>Impressum</h1>
      TBD<br>
      <button @click="impressum=false">Schließen</button>
    </popup>
  </div>
</template>

<script>
import Popup from './components/Popup.vue';

export default {
  components: { Popup },
  data() {
    return {
      impressum: false
    }
  }  
}
</script>

<style lang="scss" src="./styles/global.scss"></style>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .header {
    background: var(--color-white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 var(--dist-small) var(--dist-small) var(--color-lightgray);
    z-index: 100;

    .title {
      width: 75%;
      font-size: var(--dist-larger);
      padding: var(--dist-small) var(--dist-med);
      font-weight: 500;
    }

    .powered-container {
      min-width: 10%;
      padding: 0 var(--dist-small);

      .powered {
        color: var(--color-lightgray);

        .logo {
          display: block;
          max-height: var(--dist-larger);
          width: auto;
        }
      }
    }
  }

  .content {
    overflow: auto;
    position: relative;
    height: 100%;

    .view {
      padding: var(--dist-large);
      margin: auto;
    }
  }
}

.impressum {
  z-index: 200;
  position: absolute;
  color: var(--color-lightgray);
  right: var(--dist-smaller);
  top: var(--dist-smaller);
  cursor: pointer;
}
</style>
