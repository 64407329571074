import memoize from "memoizee";

async function getConfig(id) {
  const res = await fetch("/api/scan/" + id);

  if (res.ok) {
    return await res.json();
  } else {
    return {
      error: true,
    };
  }
}

export default memoize(getConfig, { promise: true, maxAge: 1000 * 60 * 5 });
