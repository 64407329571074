<template>
  <div>
    <div v-if="!done">email wird geändert</div>
    <div v-if="done">
      <div v-if="!error">
        <h1>geändert</h1>
        <button @click="gotoAdmin">zu den Einstellungen</button>
      </div>
      <h1 v-if="error">Fehler</h1>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      admin: "",
      done: false,
      error: false,
    };
  },

  async mounted() {
    this.admin = this.$route.params.admin;
    const token = this.$route.params.token;

    const result = await fetch("/api/admin/" + this.admin + "/email/" + token);
    this.done = true;
    this.error = !result.ok;

    if (result.ok) {
      const status = await result.json();
      this.error = status.status != "ok";
    }
  },

  methods: {
    gotoAdmin() {
      this.$router.push({ name: "Admin", params: { admin: this.admin } });
    },
  },
};
</script>
