<template>
  <div class="scan">
    <div v-if="config && data && !error">
      <div class="question">
        Bitte halten Sie Ihren Nachweis-QR-Code vor die Kamera
      </div>
      <div class="scanner">
        <qrcode-stream @decode="onDecode" :camera="camera || 'auto'">
        </qrcode-stream>
      </div>
    </div>
    <div v-if="error">Fehler</div>
    <div class="reload">
      <div class="text" @click="reload">neu laden</div>
    </div>

    <scan-approve 
      :visible="approve" 
      @close="closeApprove" 
      :id="id"
      :checked="checked"
      :qrString="qrString"
      :config="config"
    />
  </div>
</template>

<script>
import QrcodeStream from "../qr/components/QrStream.vue";
import getConfig from "../get-config";
import getData from "../get-data";
import checkDCC from "check-dcc";
import ScanApprove from "../components/ScanApprove.vue";

export default {
  name: 'Scan',

  components: {
    QrcodeStream,
    ScanApprove
  },

  data() {
    return {
      id: "",
      camera: null,
      config: null,
      data: null,
      approve: false,
      checked: {},
      qrString: ''
    };
  },

  computed: {
    error() {
      return this.config?.error || this.data?.error;
    },
  },

  async mounted() {
    this.id = this.$route.params.id;
    this.config = await getConfig(this.id);
    this.camera = this.config?.camera;
    this.data = await getData();
  },

  methods: {
    async onDecode(qrString) {
      if (this.approve) return;

      this.qrString = qrString;
      this.checked = await checkDCC(
        qrString,
        this.data?.valueSets,
        this.data?.certs,
        this.config?.rule?.rule
      );
      
      this.approve = true;
    },

    reload() {
      location.reload();
    },

    closeApprove() {
      this.checked = {};
      this.qrString = '';
      this.approve = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.scan {
  text-align: center;
}

.question {
  font-size: var(--dist-med-large);
}

.reload {
  display: inline-block;
  margin-top: var(--dist-med);
  color: var(--color-lightgray);
  .text {    
    cursor: pointer;
  }
}

.scanner {
  margin: auto;

  @media screen and (orientation: portrait) {
    max-width: 95%;
    height: auto;
  }

  @media screen and (orientation: landscape) {
    width: 80vh;
  }
}
</style>
