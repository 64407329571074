const operators = {
  and: (e, s) =>
    e.expressions.reduce((r, v) => r && evalExpression(v, s), true),
  or: (e, s) =>
    e.expressions.reduce((r, v) => r || evalExpression(v, s), false),
  not: (e, s) => !evalExpression(e.expression, s),

  equal: (e, s) => evalExpression(e.left, s) == evalExpression(e.right, s),
  notEqual: (e, s) => evalExpression(e.left, s) != evalExpression(e.right, s),
  lower: (e, s) => evalExpression(e.left, s) < evalExpression(e.right, s),
  greater: (e, s) => evalExpression(e.left, s) > evalExpression(e.right, s),
  lowerEqual: (e, s) => evalExpression(e.left, s) <= evalExpression(e.right, s),
  greaterEqual: (e, s) =>
    evalExpression(e.left, s) >= evalExpression(e.right, s),

  const: (e) => e.value,
  field: (e, s) => e.field.reduce((r, f) => r?.[f], s),

  any: (e, s) =>
    evalExpression(e.array, s)?.reduce(
      (r, i) => r || evalExpression(e.expression, i),
      false
    ),
  all: (e, s) =>
    evalExpression(e.array, s)?.reduce(
      (r, i) => r && evalExpression(e.expression, i),
      true
    ),
  none: (e, s) =>
    !evalExpression(e.array, s)?.reduce(
      (r, i) => r || evalExpression(e.expression, i),
      false
    ),

  inList: (e, s) =>
    evalExpression(e.list, s).includes(evalExpression(e.value, s)),

  now: () => Date.now(),
  isoToStamp: (e, s) => new Date(evalExpression(e.value, s)).getTime(),
  stampToIso: (e, s) => new Date(evalExpression(e.value, s)).toISOString(),

  if: (e, s) =>
    evalExpression(e.condition, s)
      ? evalExpression(e.true, s)
      : e.false
      ? evalExpression(e.false, s)
      : null,

  add: (e, s) => evalExpression(e.left, s) + evalExpression(e.right, s),
  subtract: (e, s) => evalExpression(e.left, s) - evalExpression(e.right, s),
  multiply: (e, s) => evalExpression(e.left, s) * evalExpression(e.right, s),
  divide: (e, s) => evalExpression(e.left, s) / evalExpression(e.right, s),

  setField: (e, s) =>
    (e.field.slice(0, -1).reduce((r, f) => {
      if (!r[f]) {
        r[f] = {};
      }
      return r[f];
    }, s)[e.field[e.field.length - 1]] = evalExpression(e.value, s)),
};

function evalExpression(expression, scope) {
  expression = Array.isArray(expression) ? expression : [expression];
  let result;
  expression.forEach((e) => (result = operators[e.type](e, scope)));

  return result;
}

export default evalExpression;
