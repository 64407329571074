<template>
  <div v-if="visible">
    <div class="background" :style="{'z-index': 10500 - lower*1000}" />
    <div class="popup" :class="{ maximized }" :style="{'z-index': 11000 - lower*1000}">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    maximized: {
      type: Boolean,
      default: false
    },
    lower: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style scoped lang="scss">
.background {
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--color-lightgray);
  opacity: 0.5;
}

.popup {
  text-align: left;
  position:fixed;
  z-index: 1000;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
  background: var(--color-white);
  box-shadow: 0 var(--dist-small) var(--dist-small) var(--color-lightgray);
  isolation: auto;
  overflow: auto;
  padding: var(--dist-med);
  max-height: calc(100vh - 2 * var(--dist-med));
  max-width: calc(100vw - 2 * var(--dist-med));;

  &.maximized {
    transform: unset;
    left: var(--dist-med);
    right: var(--dist-med);
    top: var(--dist-med);
    bottom: var(--dist-med);
  }
}
</style>
