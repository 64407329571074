<template>
  <div>
    <div class="description">
      <h1>Gesetzeskonforme Covid19 Zutritts-Lösung</h1>
      <p>
        Einfacher und verordnungskonformer 3G / 2,5G / 2G / 1G Check am Arbeitsplatz mit scan4enter, powered by MECHATRONIK AUSTRIA.
      </p>
      <p>
        Besondere Zeiten erfordern besondere Maßnahmen: 
        Mit dem scan4enter können Sie als Unternehmer einfach, schnell und zuverlässig den Status Ihrer Mitarbeiter kontrollieren. 
        Alles was Sie dafür benötigen: 
        Ein Tablet und unsere scan4enter Web-App. 
        Nach einer einmalig durchzuführenden Installation können die Mitarbeiter Ihren grünen Pass oder andere EU konforme Covid Zertifkate in einem Abstand von rund 10-30cm vor die Front-Kamera halten. 
        Der Scan dauert im Schnitt 1-2 Sekunden. 
        Nach erfolgreichem Scan wird sofort der aktuelle Status des Mitarbeiters angezeigt. 
        Sämtliche Corona Nachweise werden automatisiert an Ihr Mail Postfach gesendet.
      </p>
      <p>
        scan4enter wird österreichischen Unternehmen kostenlos zur Verfügung gestellt.
      </p>
      <h1>Funktionen und Vorteile</h1>
      <ul>
        <li>Unbürokratische Corona-Kontrolle für Ihre Mitarbeiter/Gäste</li>
        <li>Sicher, Einfach, Schnell</li>
        <li>KOSTENLOS</li>
      </ul>
      <h1>Anleitung</h1>
      <ol>
        <li>mit Email einmalig registrieren</li>
        <li>Bestätigungslink, der an Ihre Email Adresse gesendet wurde, bestätigen</li>
        <li>Einstellungen vornehmen</li>
        <li>Link auf Scangerät (Tablet) öffnen</li>
        <li>Fertig. Das Gerät ist bereit zum Scannen. Die Ergebnisse werden Ihnen in Ihr Email-Postfach zugestellt</li>
      </ol>
    </div>
    <div class="register">
      <h1>Registrieren</h1>
      <input v-model="email" placeholder="Email" type="email" />
      <label>
        <input v-model="agree" type="checkbox" />
        Ich bin mit den <a @click.prevent="agb = true"> Allgemeinen Geschäftsbedingungen</a> einverstanden
      </label>
      <button class="button" @click="register" :disabled="!agree">Registrierung starten</button>
    </div>
    <alert :visible="registered" :title="error ? 'Fehler' : 'Registrierung'" @close="registered=false">
      <div class="ok" v-if="!error">
          <p>Registrierung gespeichert.</p>
          <p>Bitte im Postfach bestätigen</p>
        </div>
        <div class="error" v-if="error">
          <p>Es ist ein Fehler aufgetreten.</p>
          <p>Bitte kontrollieren Sie Ihre Angaben und versuchen Sie es nochmal.</p>
        </div>
    </alert>

    <popup :visible="agb" maximized>
      TBD<br><br>
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.   
      <br>
      Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.   
      <br>
      Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.   
      <br>
      Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.   
      <br>
      Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis.   
      <br>
      At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, At accusam aliquyam diam diam dolore dolores duo eirmod eos erat, et nonumy sed tempor et et invidunt justo labore Stet clita ea et gubergren, kasd magna no rebum. sanctus sea sed takimata ut vero voluptua. est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
      <br>
      <button @click="agb=false">Schließen</button>
    </popup>
  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Popup from '../components/Popup.vue';

export default {
  components: {
    Alert,
    Popup
  },

  data() {
    return {
      email: "",
      agree: false,
      agb: false,
      registered: false,
      error: false,
    };
  },

  methods: {
    async register() {
      const result = await fetch("/api/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: this.email }),
      });

      let status = "error";

      if (result.ok) {
        const json = await result.json();
        status = json.status;
      }

      this.registered = true;
      this.error = status != "ok";
    },
  },
};
</script>
