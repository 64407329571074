import memoize from "memoizee";

async function getData() {
  const res = await fetch("/api/data");

  if (res.ok) {
    return await res.json();
  } else {
    return {
      error: true,
    };
  }
}

export default memoize(getData, { promise: true, maxAge: 1000 * 60 * 30 });
