<template>
  <div>

    <div class="settings">
      <h1>Einstellungen</h1>
      Anzeigename:
      <input v-model="name" placeholder="Anzeigename" />
      Kontrollieren auf:
      <select v-model="rule">
        <option v-for="(name, key) in rules" :key="key" :value="key">
          {{ name }}
        </option>
      </select><br />
      Kamera:
      <select v-model="camera">
        <option value="auto">
          Automatisch
        </option>
        <option value="rear">
          Hinten
        </option>
        <option value="front">
          Vorne
        </option>
      </select><br />
      <button @click="save">Speichern</button>
    </div>

    <div class="email">
      <h1>Empfangs-Email-Adresse</h1>
      <input :value="email" />
      <div v-if="emailChange" class="change">
        unbestätigte Änderung auf "{{ emailChange }}". siehe Postfach
      </div>
      <button @click="changeEmail">Ändern</button>
    </div>

    <div class="links">
      <h1>Links</h1>
      Link für stationäres Gerät:
      <div class="link">
        <input ref="stationary" :value="baseUrl + '#/scan/' + id" readonly />
        <button class="button copy" @click="copy('stationary')">kopieren</button>
        <button class="button open" @click="open('stationary')">öffnen</button>
      </div>
    </div>

    <alert :visible="settingsSaved" :title="settingsError ? 'Fehler' : 'Gespeichert'" @close="settingsSaved=false">
      <div class="ok" v-if="!settingsError">
          <p>Daten wurden erfolgreich gespeichert</p>
        </div>
        <div class="error" v-if="emailError">
          <p>Es ist ein Fehler aufgetreten.</p>
          <p>Bitte kontrollieren Sie Ihre Angaben und versuchen Sie es nochmal.</p>
        </div>
    </alert>

    <alert :visible="emailSaved" :title="emailError ? 'Fehler' : 'Gesendet'" @close="emailSaved=false">
      <div class="ok" v-if="!emailError">
          <p>Änderungsanforderung gesendet.</p>
          <p>Bitte im Postfach bestätigen</p>
        </div>
        <div class="error" v-if="emailError">
          <p>Es ist ein Fehler aufgetreten.</p>
          <p>Bitte kontrollieren Sie Ihre Angaben und versuchen Sie es nochmal.</p>
        </div>
    </alert>
  </div>
</template>

<script>
import Alert from '../components/Alert.vue'

export default {
  components: {
    Alert
  },

  data() {
    return {
      admin: "",
      rules: {},
      id: "",
      email: "",
      emailChange: false,
      rule: "",
      name: "",
      camera: "",
      baseUrl: "",
      settingsSaved: false,
      settingsError: false,
      emailSaved: false,
      emailError: false,
    };
  },

  async mounted() {
    this.admin = this.$route.params.admin;

    this.rules = await fetch("/api/admin/" + this.admin + "/rules").then(
      (res) => res.json()
    );

    await this.loadConfig();
  },

  methods: {
    async loadConfig() {
      const config = await fetch("/api/admin/" + this.admin).then((res) =>
        res.json()
      );
      this.assignConfig(config);
    },

    assignConfig(config) {
      this.id = config.id;
      this.email = config.email;
      this.emailChange = config.emailChange;
      this.rule = config.rule;
      this.name = config.name;
      this.baseUrl = config.baseUrl;
      this.camera = config.camera;
    },

    async save() {
      const newConfig = {
        rule: this.rule,
        name: this.name,
        camera: this.camera,
      };

      const result = await fetch("/api/admin/" + this.admin, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newConfig),
      });

      this.settingsSaved = true;
      this.error = !result.ok;

      setTimeout(() => (this.settingsSaved = false), 30 * 1000);

      if (result.ok) {
        const config = await result.json();
        this.assignConfig(config);
      }
    },

    async changeEmail() {
      const result = await fetch("/api/admin/" + this.admin + "/email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: this.email }),
      });

      this.emailError = !result.ok;

      if (result.ok) {
        const status = await result.json();
        this.emailError = status.status != "ok";
        this.loadConfig();
      }

      this.emailSaved = true;
      setTimeout(() => (this.emailSaved = false), 30 * 1000);
    },

    copy(ref) {
      const el = this.$refs[ref];
      el.select();
      el.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(el.value);
    },

    open(ref) {
      const el = this.$refs[ref];
      const url = el.value;
      window.open(url, '_blank');
    }
  },
};
</script>

<style lang="scss" scoped>
.link {
  display: flex;
  justify-content: space-between;

  button {
    margin-left: var(--dist-small);
  }
}
</style>
