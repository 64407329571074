import { DCC } from "dcc-utils";
import evalExpression from "./rule-checker.js";

export default async function Check(qrString, valueSets = null, certs = null, rule = null) {
  const dcc = await DCC.fromRaw(qrString);

  const result = {
    dcc,
    version: dcc.payload.ver,
    person: {
      firstName: dcc.payload.nam.gn,
      lastName: dcc.payload.nam.fn,
      birthday: dcc.payload.dob,
    },
    vaccination: null,
    test: null,
    recovery: null,
    signatureOk: null,
    rulePassed: null,
  };

  if (dcc.payload.v) {
    result.vaccination = dcc.payload.v.map((v) => ({
      disease: {
        code: v.tg,
        name: valueSets?.diseases?.valueSetValues[v.tg].display,
      },
      vaccine: {
        code: v.vp,
        name: valueSets?.vaccines?.valueSetValues[v.vp].display,
      },
      product: {
        code: v.mp,
        name: valueSets?.products?.valueSetValues[v.mp].display,
      },
      manufacturer: {
        code: v.ma,
        name: valueSets?.vaccineManufacturers?.valueSetValues[v.ma].display,
      },
      dose: v.dn,
      doseOverall: v.sd,
      date: v.dt,
      country: {
        code: v.co,
        name: valueSets?.countries?.valueSetValues[v.co].display,
      },
      issuer: v.is,
      uvci: v.ci,
    }));
  }

  if (dcc.payload.t) {
    result.test = dcc.payload.t.map((t) => ({
      disease: {
        code: t.tg,
        name: valueSets?.diseases?.valueSetValues[t.tg].display,
      },
      type: {
        code: t.tt,
        name: valueSets?.testTypes?.valueSetValues[t.tt].display,
      },
      name: t.nm,
      manufacturer: {
        code: t.ma,
        name: valueSets?.testManufacturers?.valueSetValues[t.ma].display,
      },
      datetime: t.sc,
      result: {
        code: t.tr,
        name: valueSets?.testResults?.valueSetValues[t.tr].display,
      },
      facility: t.tc,
      country: {
        code: t.co,
        name: valueSets?.countries?.valueSetValues[t.co].display,
      },
      issuer: t.is,
      uvci: t.ci,
    }));
  }

  if (dcc.payload.r) {
    result.recovery = dcc.payload.r.map((r) => ({
      disease: {
        code: r.tg,
        name: valueSets?.diseases?.valueSetValues[r.tg].display,
      },
      firstPositiveDatetime: r.fr,
      country: {
        code: r.co,
        name: valueSets?.countries?.valueSetValues[r.co].display,
      },
      validFrom: r.df,
      validTill: r.du,
      uvci: r.ci,
    }));
  }

  if (certs) {
    const certificate = await dcc.checkSignatureWithKeysList(certs);
    result.signatureOk = certificate ? true : false;
  }

  if (rule) {
    result.rulePassed = evalExpression(rule, result);
  }

  return result;
}
