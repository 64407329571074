import { createRouter, createWebHashHistory } from "vue-router";
import Register from "../views/Register.vue";
import Admin from "../views/Admin.vue";
import AdminEmail from "../views/AdminEmail.vue";
import Scan from "../views/Scan.vue";

const routes = [
  {
    path: "/",
    name: "Register",
    component: Register,
  },
  {
    path: "/admin/:admin",
    name: "Admin",
    component: Admin,
  },
  {
    path: "/admin/:admin/changeEmail/:token",
    name: "AdminEmailActivate",
    component: AdminEmail,
  },
  {
    path: "/scan/:id",
    name: "Scan",
    component: Scan,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
